import { Code, Heading, Link, ListItem, OrderedList, Text, UnorderedList } from '@chakra-ui/react';
import NextLink from 'next/link';


export const lightTheme = {
	p: props => {
		const { children } = props;
		return (
			<Text my={ 4 } fontSize={ 'xl' }>
				{ children }
			</Text>
		);
	},
	ol: props => {
		const { children } = props;
		return (
			<OrderedList my={ 4 } fontSize={ 'xl' } pl={ { base: 4, lg: 10 } }>
				{ children }
			</OrderedList>
		);
	},
	ul: props => {
		const { children } = props;
		return (
			<UnorderedList my={ 4 } fontSize={ 'xl' } pl={ { base: 4, lg: 10 } }>
				{ children }
			</UnorderedList>
		);
	},
	li: props => {
		const { children } = props;
		return (
			<ListItem my={ 2 } fontSize={ 'xl' }>
				{ children }
			</ListItem>
		);
	},
	h2: props => {
		const { children } = props;
		return (
			<Heading as="h2" fontSize={ '3xl' } fontWeight={ 'normal' } my={ 6 } variant="brand1" id={ props.id } level={ props.level }>
				{ children }
			</Heading>
		);
	},
	h3: props => {
		const { children } = props;
		return (
			<Heading as="h3" fontSize={ '2xl' } fontWeight={ 'normal' } my={ 6 } variant="brand1" id={ props.id } level={ props.level }>
				{ children }
			</Heading>
		);
	},
	h4: props => {
		const { children } = props;
		return (
			<Heading as="h4" fontSize={ 'xl' } fontWeight={ 'normal' } my={ 6 } variant="brand1" id={ props.id } level={ props.level }>
				{ children }
			</Heading>
		);
	},
	a: props => {
		const { children, href } = props;
		return (
			<Link as={ NextLink } href={ href } color="brand2.700">
				{ children }
			</Link>
		);
	},
	code: props => {
		return (
			<Code>{ props.children }</Code>
		);
	},
};
