import { Link } from '@chakra-ui/next-js';
import { Alert, AlertIcon, Box, Button, Checkbox, Container, FormControl, FormHelperText, Heading, Input, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { gtmInsertEventInDataLayer } from '../../util/gtm';

export default function NewsletterForm() {

	const [message, setMessage] = useState( '' );

	const subscribeUser = async ( e ) => {
		e.preventDefault();

		const res = await fetch( '/api/subscribe', {
			body: JSON.stringify( {
				email: e.target.email.value,
				privacy: e.target.privacy.checked,
				newsletter: e.target.newsletter.checked,
				marketing: e.target.marketing.checked
			} ),
			headers: {
				'Content-Type': 'application/json'
			},
			method: 'POST'
		} );

		const { error } = await res.json();

		if ( error ) {
			// 4. If there was an error, update the message in state.
			setMessage( error );

			return;
		}

		gtmInsertEventInDataLayer( {
			event: 'generate_lead',
		} );

		setMessage( 'Ci sei quasi! Controlla la tua casella di posta per confermare la tua iscrizione.' );
		// 5. Clear the input value and show a success message.
		e.target.reset();

	};

	return (
		<Container py="8" maxW={ { base: 'xl', md: '7xl' } } mx="auto" px={ { base: '6', md: '8', } }>
			<Stack spacing={ { base: '8', md: '10' } } align="center" py="12" direction={ { base: 'column', md: 'row' } } alignItems={ "flex-start" } mt={ 12 }>
				<Stack spacing={ { base: '4', md: '5' } } flexBasis={ "50%" } position={ "relative" }>
					<Heading as="h2" fontSize="3xl" variant="brand1" mb="4" fontWeight={ 'normal' }>Diventiamo amici di penna? ✒️</Heading>
					<Text fontSize="lg">
						Iscriviti alla newsletter per ricevere una mail ogni paio di settimane con le ultime novità, gli ultimi approfondimenti e gli ultimi corsi gratuiti puubblicati. Ogni tanto potrei scrivere qualcosa di commerciale, ma solo se mi autorizzi, altrimenti non ti disturberò oltre.
					</Text>
					<Text fontSize="lg">
						Se non ti piace la newsletter ti ricordo la community su <Link href={ "https://discord.gg/wcRzgYWB" } target='_blank'>Discord</Link>, dove puoi chiedere aiuto, fare domande e condividere le tue esperienze (ma soprattutto scambiare due meme con me). Ti aspetto!
					</Text>
				</Stack>
				<Box flexBasis={ "50%" }>
					<form onSubmit={ subscribeUser }>
						<Stack spacing="4" maxW={ "3xl" }>
							<FormControl mb={ 4 }>
								<Input size="lg" colorScheme="brand1" type="email" name='email' placeholder="La tua migliore email" required />
								<FormHelperText color="fg.subtle">Ho in previsione di mandarti una newsletter ogni due settimane e una commerciale quando capita.</FormHelperText>
							</FormControl>
							<FormControl>
								<Checkbox size="lg" colorScheme="brand1" name='privacy' required>
									<Text fontSize={ 'md' } as={ 'span' }>
										Dichiaro di aver preso visione della <Link href={ "/privacy-policy" } target='_blank'>privacy policy</Link> e di accetterla integralmente
									</Text>
								</Checkbox>
							</FormControl>
							<FormControl>
								<Checkbox size="lg" colorScheme="brand1" name='newsletter'>
									<Text fontSize={ 'md' } as={ 'span' }>
										Newsletter informativa con news interessanti, aggiornamenti sui corsi gratuiti e sulle ultime iniziative
									</Text>
								</Checkbox>
							</FormControl>
							<FormControl>
								<Checkbox size="lg" colorScheme="brand1" name='marketing'>
									<Text fontSize={ 'md' } as={ 'span' }>
										Email commerciali. Brutto nome, ma se mi autorizzi ti potrò avvisare quando qualcosa che mi porta profitto è pronto per essere acquistato.
									</Text>
								</Checkbox>
							</FormControl>
							<Button type="submit" size="md" colorScheme="brand1" variant="solid" alignSelf={ 'flex-end' } mt={ 4 }>
								Iscriviti
							</Button>
							{ message && ( <Alert status='info'><AlertIcon />{ message }</Alert> ) }
						</Stack>
					</form>
				</Box>
			</Stack>
		</Container>
	);
}
