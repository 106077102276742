import { getStrapiMedia } from "lib/media";
import Head from "next/head";
import { useRouter } from 'next/router';

const Seo = ( { seo } ) => {
	const { asPath } = useRouter();
	const defaultSeo = {
		metaTitle: "Imparare Coding con Manuel",
		metaDescription: "Impara a programmare con i miei corsi gratuiti e le mie guide. Troverai contenuti su HTML, CSS, JavaScript, React, Next.js, Node.js, MongoDB e molto altro.",
		shareImage: null,
		canonical: null,
	}
	const siteName = "Imparare Coding con Manuel";
	const seoWithDefaults = {
		...defaultSeo,
		...seo,
	};
	const fullSeo = {
		...seoWithDefaults,
		// Add title suffix
		metaTitle: `${seoWithDefaults.metaTitle} | ${siteName}`,
		// Get full image URL
		shareImage: ( seoWithDefaults.shareImage?.data ) ? getStrapiMedia( seoWithDefaults.shareImage ) : null,
	};

	return (
		<Head>
			{ fullSeo.metaTitle && (
				<>
					<title>{ fullSeo.metaTitle }</title>
					<meta property="og:title" content={ fullSeo.metaTitle } />
					<meta name="twitter:title" content={ fullSeo.metaTitle } />
				</>
			) }
			{ fullSeo.metaDescription && (
				<>
					<meta name="description" content={ fullSeo.metaDescription } />
					<meta property="og:description" content={ fullSeo.metaDescription } />
					<meta name="twitter:description" content={ fullSeo.metaDescription } />
				</>
			) }
			{ fullSeo.shareImage && (
				<>
					<meta property="og:image" content={ fullSeo.shareImage } />
					<meta name="twitter:image" content={ fullSeo.shareImage } />
					<meta name="image" content={ fullSeo.shareImage } />
				</>
			) }
			{ fullSeo.article && <meta property="og:type" content="article" /> }
			<meta name="twitter:card" content="summary_large_image" />
			{ fullSeo.structuredData && (
				<script type="application/ld+json" dangerouslySetInnerHTML={ fullSeo.structuredData } key="page-jsonld"></script>
			) }
			<link rel="canonical" href={ fullSeo.canonical ? fullSeo.canonical : `https://www.manuelricci.com${asPath}` } />
		</Head>
	);
};

export default Seo;
