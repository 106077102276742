import { Badge, Card as CCard, Heading, Stack } from '@chakra-ui/react';
import Link from "next/link";
import { getStrapiMedia } from '../lib/media';
import { Author } from './common/Author';

const CourseCard = ( { course } ) => {
	return (
		<Link href={ `/corsi-gratuiti-programmazione/${course.attributes.slug}` }>
			<CCard borderRadius="20px" minH="215px" _before={ { content: '""', bgImage: getStrapiMedia( course.attributes.thumbnail ), bgPos: "center", bgSize: "cover", top: 0, bottom: 0, left: 0, right: 0, opacity: 0.3, position: "absolute" } } position="relative" overflow="hidden">
				<Stack spacing={ 8 } justifyContent="space-between" backdropFilter="auto" backdropBlur="10px" py={ 8 } px={ 4 }>
					<Badge variant="subtle" colorScheme="brand1" alignSelf="flex-start">{ course.attributes.courseCategory.data.attributes.name }</Badge>
					<Heading as="h2" fontSize="2xl">{ course.attributes.title }</Heading>
					<Author name={ course.attributes.courseAuthor.data.attributes.name } picture={ course.attributes.courseAuthor.data.attributes.picture } />
				</Stack>
			</CCard>
		</Link>
	);
};

export default CourseCard;
