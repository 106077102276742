const { HStack, Avatar, Box, Text } = require( '@chakra-ui/react' );
const { getStrapiMedia } = require( '../../lib/media' );

export const Author = ( { name, picture } ) => {
	return (
		<HStack>
			<Avatar name={ name } src={ getStrapiMedia( picture ) } />
			<Box fontSize="sm">
				<Text>Autore</Text>
				<Text fontWeight={ 700 }>{ name }</Text>
			</Box>
		</HStack>
	);
};
