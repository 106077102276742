import { Box, SimpleGrid } from '@chakra-ui/react';
import CourseCard from './coursecard';

const CoursesList = ( { courses } ) => {

	return (
		<Box as="section">
			<SimpleGrid columns={ { base: 1, md: 2 } } spacing={ 8 }>
				{ courses.map( ( course, i ) => {
					return (
						<Box key={ course.attributes.slug }>
							<CourseCard course={ course } />
						</Box >
					);
				} ) }
			</SimpleGrid>
		</Box>
	);
};

export default CoursesList;
