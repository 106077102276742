import { Box, Heading } from '@chakra-ui/react';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import Layout from "components/layout";
import Seo from 'components/seo';
import dynamic from 'next/dynamic';
import CoursesList from '../../components/courses';
import NewsletterForm from '../../components/newsletter/Newsletter';
import { lightTheme } from '../../lib/lightmarkdown';


const MarkdownInterpreter = dynamic( () => import( 'markdown-to-jsx' ) );

const Home = ( { courses, categories, homepage } ) => {
	return (
		<Layout categories={ categories }>
			<Seo seo={ homepage.attributes.seo } />
			<Box as="section" py="12" maxW={ { base: 'xl', md: '7xl' } } mx="auto" px={ { base: '6', md: '8', } }>
				<Heading as="h1" fontSize="3xl" variant="brand1" mb="4" fontWeight={ 'normal' }>{ homepage.attributes.hero.title }</Heading>
				{ homepage.attributes.hero.content && (
					<MarkdownInterpreter options={ {
						forceBlock: true,
						overrides: ChakraUIRenderer( lightTheme ),
					} }>{ homepage.attributes.hero.content }</MarkdownInterpreter>
				) }
				<Heading as="h2" fontSize="3xl" variant="brand1" mt="24" mb="12" fontWeight={ 'normal' }>Ultimi corsi pubblicati</Heading>
				<CoursesList courses={ courses } />
				<NewsletterForm />
			</Box>
		</Layout>
	);
};

export async function getStaticProps() {

	const fetchAPI = (await import( "lib/api" )).fetchAPI;

	const [coursesRes, categoriesRes, homepageRes] = await Promise.all( [
		fetchAPI( "/courses", { populate: ["thumbnail", "courseCategory", "courseAuthor.picture", "seo"], sort: ["updatedAt:desc"] } ),
		fetchAPI( "/course-categories", { populate: "*" } ),
		fetchAPI( "/homepage", {
			populate: {
				hero: "*",
				seo: { populate: "*" },
			},
		} ),
	] );

	return {
		props: {
			courses: coursesRes.data,
			categories: categoriesRes.data,
			homepage: homepageRes.data,
		},
		revalidate: 1,
	};
}

export default Home;
